import landing from "../assets/landLandLAND.jpg"
import openGate from "../assets/openGateHell2.png"

export default function Home() {
  function enterGallery() {
    window.location.href = 'MainGallery';    
  }

  return (
    <>
      <section id='homePage' className="">
        <main className="">
          <section id="galleryPortal" className="">
            <div className='md:overflow-hidden'>
              <img src={landing} className="absolute opacity-100"
              width={2200}
              height={550}
              alt='Illustration of garden gate'
              />
              <img src={openGate} className=" hover:opacity-100 opacity-0 absolute z-20 mt-[48.7%]"
              width={2200}
              height={200}
              alt='Illustration of open garden gate'
              onClick={enterGallery}
              />
            </div>
          </section>
        </main>
      </section>
    </>
  );
};