import {useState} from 'react'
import NavBar from './NavBar';
import Footer4 from './Footer4';
import Modal from './Modal';
import kimia1 from "../assets/kimia1.jpg"
import kimia2 from "../assets/kimia2.jpg"
import kimia3 from "../assets/kimia3.jpg"
import danny1 from "../assets/danny1.jpg"
import danny2 from "../assets/danny2.jpg"
import danny3 from "../assets/danny3.jpg"
import dana1 from "../assets/dana1.jpg"
import dana2 from "../assets/dana2.jpg"
import dana3 from "../assets/dana3.jpg"
import bubb from "../assets/bubb2.png"
import model13 from "../assets/model13.png"

function Marketplace() {
  // kimia
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);

  // dana
  const [showModal4, setShowModal4] = useState(false);
  const [showModal5, setShowModal5] = useState(false);
  const [showModal6, setShowModal6] = useState(false);

  // danny
  // const [showModal7, setShowModal7] = useState(false);
  // const [showModal8, setShowModal8] = useState(false);
  // const [showModal9, setShowModal9] = useState(false);

  return (
    <>
      <section id='marketplace' className=" grid place-items-center">
        <header className="">
          <section className="">
            <NavBar />
          </section>
        </header>

        <main className="" style={{padding: '5% 5% 2% 5%'}}>
        {/* showModal */}
        <Modal id='tarot' onClose={() => setShowModal(false)} visible={showModal}>
        <div className='flex justify-center	space-x-0 max-w-[1000px] max-h-[600px]'> 
        <div className='lg:mt-[5%] lg:w-[120%] sm:ml-[-10%]  md:ml-10 md:mt-16 sm:w-50 sm:mr-0 sm:mt-0 lg:mr-40 sm:text-xs' id='info'>
          <h2>As Fate Would Have It</h2>
          <br/>
          <p>
          Kimia Ghofrani <br/>
          <br/>
          watercolour, ink, guache
          <br/>
          2020
          <br/>
          <br/>
          Six tarot cards that capture the transformative journey<br/>
          of an artist-turned-tightrope walker.<br/> Each card is a step away
          from the city's roar, towards the circus's embrace—a
          quest for  authenticity with the undercurrents of a darker ambition. <br/>
          These images confront the paradox of fame: the allure of legacy 
          against the stark backdrop of sacrifice.<br/> The tightrope is thin, and the
          fall is steep. <br/>For some, immortality's touch comes only in the quiet 
          after the final bow. <br/>This suite is a tribute to the unseen odyssey behind
          every artist's eye, a condensed epic for the viewer's heart—an invitation
          to ponder the price of our dreams. <br/>
          <br/><br/><br/><br/><br/></p>
          <br/>
          <a href='mailto:kimiaghofrani1997@gmail.com?subject=Saw your work on cgac.ca and want to learn more!&body=Hi, I saw As Fate Would Have It on cgac.ca and am interested in learning some more about this piece..'>click here to email the artist directly!</a>
        </div>

        <div className='' id='image'>
          <img className="sm:ml-5 sm:mt-[90%] md:mt-[40%] rounded-full lg:ml-[-30%] lg:mt-[15%]" src={kimia1} alt="" height={500} width={500} />
        </div>
        </div>
        </Modal>
        {/* showModal ends */}

        {/* showModal2 */}
        <Modal id='bearded' onClose={() => setShowModal3(false)} visible={showModal3}>
        <div className='flex justify-center	space-x-0 max-w-[1000px] max-h-[600px]'> 
        <div className='lg:mt-[2%] lg:w-[60%] md:w-72 sm:ml-[-10%] md:mt-[-12%] sm:w-96 sm:mr-0 sm:mt-[-20%] lg:mr-20 lg:ml-10 sm:text-xs' id='info'>
        <h2>"In the Embrace of the Bearded Lady"</h2>
          <br/>
          <p>
          Kimia Ghofrani <br/>
          <br/>
          wood, watercolour, guache
          <br/>
          2019
          <br/>
          <br/>
          In this evocative piece, Mango Lassi, a revered icon from Toronto's vibrant drag scene, is immortalized persenting a tableau that is reminiscent of the enchanting whimsy of puppet shows and fairground carousels. <br/>
          The artwork unfurls in the intimate space of a dressing room, where three mirrors reflect the layered narratives of femininity through the lens of the marginalized. <br/>
          Each reflection probes a different facet of identity—disability, race, and body shape—challenging the viewer to confront the conventional standards of a 'desirable' woman. <br/>
          In the central narrative, Mango Lassi rises as a nurturing yet defiant figure, embodying the stigmatized attributes with a regal grace that defies societal expectations. <br/>
          Ornimented with jasmine, a symbol deeply rooted in her Pakistani heritage, Mango Lassi holds a serpent, a nod to Eve's first sin, weaving a complex story about the roles assigned to women and the inherent rebellion in choosing to redefine them.
          This portrayal of the Bearded Lady is a powerful meditation on the transformative beauty of self-expression. <br/>
          It is an ode to the courage of living one's truth against the backdrop of a society that often confines femininity to a narrow spectrum.  <br/>
          Here, the artist invites us to celebrate the vastness of womanhood, in all its forms, as a spectacle of true desire.
          <br/><br/><br/><br/><br/>
          </p>
          <br/>
          <a href='mailto:kimiaghofrani1997@gmail.com?subject=Saw your work on cgac.ca and want to learn more!&body=Hi, I saw "In the Embrace of the Bearded Lady" on cgac.ca and am interested in learning some more about this piece..'>click here to email artist directly!</a>
        </div>

        <div className='sm:mt-[50%] sm:w-2/3' id='image'>
          <img className="sm:ml-5 sm:mt-[-70%] md:mt-[-80%] md:ml-20 rounded-full ml-20 lg:mt-[-100%]" src={kimia2} alt="" height={300} width={300} />
        </div>
        </div>
        </Modal>
        {/* showModal2 ends */}

        {/* showModal3 */}
        <Modal id='cow' onClose={() => setShowModal2(false)} visible={showModal2}>
        <div className='flex justify-center	space-x-0 max-w-[1000px] max-h-[600px]'> 
        <div className='lg:mt-[2%] lg:w-[60%] md:w-72 sm:ml-[-10%] md:mt-[-12%] sm:w-96 sm:mr-0 sm:mt-[-20%] lg:mr-20 lg:ml-10 sm:text-xs' id='info'>
          <h2>The Sacred Bull</h2>
          <br/>
          <p>
          Kimia Ghofrani <br/>
          <br/>
          wood, watercolour, ink, acrylic, metal
          <br/>
          2019
          <br/>
          <br/>
          P. T. Barnum's legendary "Sacred Bull" is reimagined as a revealing spectacle, dissected into butcher's cuts—a stark visualization of it's commodification.<br/>
          The bull, once a symbol of cosmic unity, as represented by It's advertised Hindu Orgins and the freshly tattooed World Tarot card, is now segmented, its wholeness broken.<br/>
          This mix media piece is a commentary on the cultural commodification that pervades our global marketplace, questioning the ethics of cultural appropriation for profit.<br/>
          It challenges the viewer to consider the depth of understanding—or lack thereof—that accompanies the Western fascination with mysticism and the consumption of the 'other.'<br/>
          Through the juxtaposition of sacred symbolism and the profane realities of capitalism, the work asks: How do we reconcile the quest for global connection with the mechanics of cultural exploitation?<br/>
          Where does cultural appreciation end and appropriation begin in an economy that often prizes spectacle over substance?<br/>
          This bull, a mythical entity repackaged for mass consumption, becomes a mirror reflecting our own societal practices—inviting us to ponder the true cost of 'ethical globalization' in a capitalist world.
          <br/><br/><br/><br/><br/>
          </p>
          <br/>
          <a href='mailto:kimiaghofrani1997@gmail.com?subject=Saw your work on cgac.ca and want to learn more!&body=Hi, I saw The Sacred Bull on cgac.ca and am interested in learning some more about this piece..'>click here to email artist directly!</a>
        </div>

        <div className='sm:mt-[40%] md:mt-20 lg:mt-20 sm:w-2/3' id='image'>
          <img className="sm:scale-100 sm:ml-5 scale-125" src={kimia3} alt="" height={500} width={500} />
        </div>
        </div>
        </Modal>
        {/* showModal3 ends */}

        {/* showModal4 */}
        <Modal id='pom' onClose={() => setShowModal4(false)} visible={showModal4}>
        <div className='flex justify-center	space-x-0 max-w-[1000px] max-h-[600px]'> 
        <div className='lg:mt-[10%] lg:w-[50%] md:w-48 md:mr-10 md:mt-[-2%] sm:w-96 sm:mr-0 sm:mt-[-5%] lg:mr-20 lg:ml-10 sm:text-xs' id='info'>
          <h2>rip into me</h2>
          <br/>
          <p>
          Dana Filatov <br/>
          <br/>
          oil on canvas
          <br/>
          2019
          <br/>
          <br/>
          for information about this piece please contact the artist
          <br/><br/><br/><br/><br/>
          </p>
          <br/>
          <a href='mailto:danafeldman97@gmail.com?subject=Saw your work on cgac.ca and want to learn more!&body=Hi, I saw rip into me on cgac.ca and am interested in learning some more about this piece..'>click here to email artist directly!</a>
        </div>

        <div className='sm:mt-[50%] sm:w-2/3' id='image'>
          <img className="sm:ml-3 mt-[-80%] scale-110 mr-10 ml-[-5%]" src={dana1} alt="" height={500} width={500} />
        </div>
        </div>
        </Modal>
        {/* showModal4 ends */}

        {/* showModal5 */}
        <Modal id='baby' onClose={() => setShowModal5(false)} visible={showModal5}>
        <div className='flex justify-center	space-x-0 max-w-[1000px] max-h-[600px]'> 
        <div className='lg:mt-[10%] lg:w-[50%] md:w-48 md:mr-10 md:mt-[-2%] sm:w-96 sm:mr-0 sm:mt-[-5%] lg:mr-20 lg:ml-10 sm:text-xs' id='info'>
          <h2>ugly baby</h2>
          <br/>
          <p>
          Dana Filatov <br/>
          <br/>
          oil on canvas
          <br/>
          2019
          <br/>
          <br/>
          for information about this piece please contact the artist
          <br/><br/><br/><br/><br/>
          </p>
          <br/>
          <a href='mailto:danafeldman97@gmail.com?subject=Saw your work on cgac.ca and want to learn more!&body=Hi, I saw ugly baby on cgac.ca and am interested in learning some more about this piece..'>click here to email artist directly!</a>
        </div>


        <div className='sm:mt-[50%] sm:w-2/3' id='image'>
          <img className="sm:ml-3 mt-[-80%] scale-110 mr-10 ml-[-5%]" src={dana3} alt="" height={400} width={400} />
        </div>
        </div>
        </Modal>
        {/* showModal5 ends */}

        {/* showModal6 */}
        <Modal id='flowers' onClose={() => setShowModal6(false)} visible={showModal6}>
        <div className='flex justify-center	space-x-0 max-w-[1000px] max-h-[600px]'> 
        <div className='lg:mt-[10%] lg:w-[50%] md:w-48 md:mr-10 md:mt-[-2%] sm:w-96 sm:mr-0 sm:mt-[-5%] lg:mr-20 lg:ml-10 sm:text-xs' id='info'>
          <h2>a celebration of the mistreated and misunderstood</h2>
          <br/>
          <p>
          Dana Filatov <br/>
          <br/>
          oil on linen
          <br/>
          2019
          <br/>
          <br/>
          for information about this piece please contact the artist
          <br/><br/><br/><br/><br/>
          </p>
          <br/>
          <a href='mailto:danafeldman97@gmail.com?subject=Saw your work on cgac.ca and want to learn more!&body=Hi, I saw a celebration of the mistreated and misunderstood on cgac.ca and am interested in learning some more about this piece..'>click here to email artist directly!</a>
        </div>


        <div className='sm:mt-[40%] sm:w-2/3' id='image'>
          <img className="sm:ml-3 mt-[-80%] scale-110 mr-10 ml-[-5%]" src={dana2} alt="" height={400} width={400} />
        </div>
        </div>
        </Modal>
        {/* showModal6 ends */}

        {/* showModal7 */}
        {/* <Modal id='snake' onClose={() => setShowModal7(false)} visible={showModal7}> */}
        {/* <div className='flex'> 
        <div className='w-1/2 sm:ml-[-15%]' id='info'>
          <h2>TITLE OF PIECE</h2>
          <br/>
          <p>
          ARTIST NAME <br/>
          <br/>
          <br/>
          info <br/>
          info <br/>
          info <br/>
          info <br/>
          <br/><br/><br/><br/><br/><br/><br/>
          contact info</p>
          <br/>
          <a href='mailto:danielnfcardoso@gmail.com?subject=Saw your work on cgac.ca and want to learn more!&body=Hi, I saw your work on cgac.ca and am interested in learning some more about this piece..'>click here to email artist directly!</a>
        </div>

        <div className='sm:mt-[50%] sm:w-2/3' id='image'>
          <img className="sm:scale-125 sm:ml-3 lg:-mt-[17%]" src={danny1} alt="" height={500} width={500} />
        </div>
        </div> */}
        {/* </Modal> */}
        {/* showModal7 ends */}

        {/* showModal8 */}
        {/* <Modal id='eye' onClose={() => setShowModal8(false)} visible={showModal8}> */}
        {/* <div className='flex'> 
        <div className='w-1/2 sm:ml-[-15%]' id='info'>
          <h2>TITLE OF PIECE</h2>
          <br/>
          <p>
          ARTIST NAME <br/>
          <br/>
          <br/>
          info <br/>
          info <br/>
          info <br/>
          info <br/>
          <br/><br/><br/><br/><br/><br/><br/>
          contact info</p>
          <br/>
          <a href='mailto:danielnfcardoso@gmail.com?subject=Saw your work on cgac.ca and want to learn more!&body=Hi, I saw your work on cgac.ca and am interested in learning some more about this piece..'>click here to email artist directly!</a>
        </div>

        <div className='sm:mt-[50%] sm:w-2/3' id='image'>
          <img className="lg:-mt-[17%] sm:scale-125 sm:ml-0 sm:mt-5" src={danny2} alt="" height={400} width={400} />
        </div>
        </div> */}
        {/* </Modal> */}
        {/* showModal8 ends */}

        {/* showModal9 */}
        {/* <Modal id='' onClose={() => setShowModal9(false)} visible={showModal9}> */}
        {/* <div className='flex'> 
        <div className='w-1/2 sm:ml-[-15%]' id='info'>
          <h2>TITLE OF PIECE</h2>
          <br/>
          <p>
          ARTIST NAME <br/>
          <br/>
          <br/>
          info <br/>
          info <br/>
          info <br/>
          info <br/>
          <br/><br/><br/><br/><br/><br/><br/>
          contact info</p>
          <br/>
          <a href='mailto:danielnfcardoso@gmail.com?subject=Saw your work on cgac.ca and want to learn more!&body=Hi, I saw your work on cgac.ca and am interested in learning some more about this piece..'>click here to email artist directly!</a>
        </div>

        <div className='sm:mt-[50%] sm:w-2/3' id='image'>
          <img className="mt-10 scale-125 ml-[-5%] sm:scale-125 sm:ml-0 sm:mt-2" src={danny3} alt="" height={400} width={400} />
        </div>
        </div> */}
        {/* </Modal> */}
        {/* showModal9 ends */}

        {/* <h2 className='lg:text-6xl md:text-3xl sm:text-xl text-center mt-60 text-olive2'>🚧 Marketplace under construction 🚧</h2>
        <h2 className='lg:text-6xl md:text-3xl sm:text-xl text-center mt-4 sm:mt-0 sm:mb-10 mb-20 text-olive2'>coming soon!</h2> */}
        
        <div className='flex sm:flex-col px-40 lg:mt-[-65%] sm:mt-40 sm:mb-20 md:mt-[-20%]'>
          <div className="sm:mt-[95%] ml-[-2%] sm:scale-150 md:scale-75 max-w-[600px] min-h-[200px]">
           <img className="rounded-full" src={model13} alt="" height={600} width={800} />
          </div>
          <div className="lg:ml-[40%] sm:ml-[-2%] lg:mt-[-40%] sm:mt-5 sm:scale-150 md:mt-[-50%] md:scale-75">
            <img className="rounded-full " src={bubb} alt="" height={600} width={600} />
          </div>
        </div>
  

          {/* kimia */}
          <div id="marketArtworks" className="lg:flex-row items-center flex md:flex-col sm:flex-col w-[100%] gap-10">
            <div className="bg-whte overflow-hidden rounded-full lg:max-w-[500px] lg:max-h-[500px] md:max-w-[200px] md:max-h-[200px] cursor-pointer">
              <img className="lg:-mt-[10%] rounded-full sm:mb-[-10%] sm:mt-[-10%]" src={kimia1} alt="" height={500} width={500} onClick={() => setShowModal(true)} />
            </div>
            <div className="bg-white overflow-hidden rounded-full lg:max-w-[500px] lg:max-h-[500px] md:max-w-[400px] md:max-h-[400px] cursor-pointer">
              <img src={kimia2} alt="" height={500} width={500} className='mt-[-10%] sm:mb-[-35%] sm:mt-[-55%]' onClick={() => setShowModal3(true)} />
            </div>
            <div className="bg-white overflow-hidden items-center rounded-full lg:max-w-[600px] lg:max-h-[600px] md:max-w-[200px] md:max-h-[200px] cursor-pointer">
              <img src={kimia3} className='sm:mb-[10%] sm:mt-[10%]' alt="" height={500} width={500} onClick={() => setShowModal2(true)}/>
            </div>
          </div>
          {/* kimia ends */}

          {/* dana */}
          <div id="marketArtworks" className="mt-40 sm:mt-20 lg:flex-row items-center flex md:flex-col sm:flex-col w-[100%] gap-10">
            <div className="bg-whte overflow-hidden rounded-full lg:max-w-[500px] lg:max-h-[500px] md:max-w-[200px] md:max-h-[200px] cursor-pointer">
              <img className="rounded-full sm:mb-[5%] sm:mt-[5%]" src={dana1} alt="" height={500} width={500} onClick={() => setShowModal4(true)} />
            </div>
            <div className="bg-white overflow-hidden items-center rounded-full lg:max-w-[500px] lg:max-h-[500px] md:max-w-[200px] md:max-h-[200px] cursor-pointer">
              <img src={dana3} className='mt-[-5%] sm:mb-[-10%] sm:mt-[-10%]' alt="" height={500} width={500} onClick={() => setShowModal5(true)}/>
            </div>
            <div className="bg-white overflow-hidden rounded-full lg:max-w-[500px] lg:max-h-[500px] md:max-w-[400px] md:max-h-[400px] cursor-pointer">
              <img src={dana2} alt="" height={500} width={500} className='sm:mb-[10%] sm:mt-[10%]' onClick={() => setShowModal6(true)} />
            </div>
          </div>
          {/* dana ends */}

          {/* danny */}
          <div id="marketArtworks" className="mt-20 lg:flex-row items-center flex md:flex-col sm:flex-col w-[100%] gap-16">
            <div className="bg-white overflow-hidden items-center rounded-full lg:max-w-[500px] lg:max-h-[500px] md:max-w-[200px] md:max-h-[200px] cursor-pointer">
            {/* <img className="lg:-mt-[17%] rounded-full sm:mb-[-15%] sm:mt-[-15%]" src={danny1} alt="" height={500} width={500} onClick={() => setShowModal7(true)} /> */}
              <img className="lg:-mt-[17%] rounded-full sm:mb-[-15%] sm:mt-[-15%]" src={danny1} alt="" height={500} width={500} />
            </div>
            <div className="bg-white overflow-hidden rounded-full max-w-[500px] max-h-[500px] cursor-pointer">
              {/* <img src={danny2} className='sm:mb-[10%] sm:mt-[10%]' alt="" height={500} width={500} onClick={() => setShowModal8(true)}/> */}
              <img src={danny2} className='sm:mb-[10%] sm:mt-[10%]' alt="" height={500} width={500}/>
            </div>
            <div className="bg-white overflow-hidden rounded-full max-w-[500px] max-h-[500px] cursor-pointer">
            {/* <img src={danny3} className='sm:mb-[-10%]' alt="" height={500} width={500} onClick={() => setShowModal9(true)}/> */}
              <img src={danny3} className='sm:mb-[-10%]' alt="" height={500} width={500}/>
            </div>
          </div>
          {/* danny ends */}
        </main>

        <footer className='bg-olive mt-16'>
          <Footer4 />
        </footer>
      </section>
    </>
  );
}

export default Marketplace