import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import oliveLogo from "../assets/logoOliveHR.png"
import mossLogo from "../assets/logoMossHR.png"
import persimmonLogo from "../assets/logoPersimmonHR.png"
import princeLogo from "../assets/logoPrinceOrange.png"

function Nav() {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleNav = () => {
    setMenuOpen(!menuOpen)
    if (!menuOpen) {
      document.getElementById("burg").style.display="none";  
  } else {
    document.getElementById("burg").style.display="flex"
  }
  }

  return (
    <>
    <nav className="font-body ">
      <div className="justify-between items-center flex">
        <ul style={{padding: '5% 5% 2% 5%'}} className="z-10 md:visible sm:invisible justify-center flex w-screen text-center md:gap-8 lg:gap-12 sm:text-sm md:text-md lg:text-lg text-persimmon">
          <NavLink to={"/MainGallery"}>
            <li className="hover:text-princeOrangeOG  mt-1 lg:text-2xl ">Main Gallery</li>
          </NavLink>
          <NavLink to={"/Calendar"}>
            <li className="hover:text-princeOrangeOG mt-1 lg:text-2xl">Calendar</li>
          </NavLink>
            
          <NavLink to="/">
            <img className='mt-[-5%] lg:scale-150 md:scale- hover:opacity-0'
            src={persimmonLogo}
            width={100}
            height={100}
            alt='CGAC logo'
            />
            <img className='mt-[-49%] lg:scale-150 md:scale- opacity-0 hover:opacity-100'
            src={princeLogo}
            width={100}
            height={100}
            alt='CGAC logo'
            />
          </NavLink>
            
          <NavLink to={"/Marketplace"}>
            <li className="hover:text-princeOrangeOG mt-1 lg:text-2xl">Marketplace</li>
          </NavLink>
            {/* <Link href={"/ingen"}>
              <li className="hover:text-olive">InGen</li>
            </Link> */}
            {/* <Link href={"/archive"}>
              <li className="hover:text-olive">Archive</li>
            </Link> */}
          <NavLink to={"/AboutUs"}>
            <li className="hover:text-princeOrangeOG mt-1 lg:text-2xl">About us</li>
          </NavLink>
        </ul>
      </div>
        
      {/* HAMBURGER */}
      <div className= "z-20 md:hidden cursor-pointer pl-24 mt-[-10%] pb-5 hover:text-olive text-olive2 relative">
        <GiHamburgerMenu onClick={handleNav} className='z-20' id='burg'/>
      </div>    

      {/* SLIDE OUT NAV */}
      <div id='slideOut' className={menuOpen ? "absolute z-10 top-0 md:hidden w-screen p-6 mb-10 content-center" : "left[-100%] absolute"}>
      <div className={menuOpen ? "flex w-full items-center justify-end" : "hidden"}>
        <div onClick={handleNav} className="cursor-pointer mt-[-2%]">
          <AiOutlineClose />
        </div>
      </div>
      <div className={menuOpen ? "flex float-left" : "hidden"}>
        <ul className='flex w-100 text-center sm:gap-3  '>
          <NavLink to={"/MainGallery"}>
            <li className="hover:text-olive text-olive2 sm:text-sm mr-1">Main Gallery</li>
          </NavLink>
          <NavLink to={"/Calendar"}>
            <li className="hover:text-olive text-olive2 sm:text-sm ">Calendar</li>
          </NavLink>
          <NavLink to="/">
            <li>
              <img className='mt-[-20%] left-36 hover:opacity-0'
              src={mossLogo}
              width={50}
              height={50}
              alt='CGAC logo'
              />
            </li>
            <li>
              <img className='mt-[-48%] left-36 opacity-0 hover:opacity-100'
              src={oliveLogo}
              width={50}
              height={50}
              alt='CGAC logo'
              />
            </li>
          </NavLink>
          <NavLink to={"/Marketplace"}>
            <li className="hover:text-olive sm:text-sm ml-[-10%]">Marketplace</li>
          </NavLink>
            {/* <Link href={"/archive"}>
              <li className="hover:text-olive text-olive2">Archive</li>
            </Link> */}
         <NavLink to={"/AboutUs"}>
            <li className="hover:text-olive sm:text-sm">About us</li>
          </NavLink>
        </ul>
      </div>
      </div>
    </nav>
    </>
  );
} 

export default Nav