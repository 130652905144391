import React from 'react'
import NavBar from './NavBar';
import Footer2 from './Footer2';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import artLeaf1 from "../assets/artistbranch-05.png"
import artLeaf2 from "../assets/artistbranch-06.png"
import artLeaf3 from "../assets/artistbranch-04.png"
import artLeaf4 from "../assets/artistbranch-03.png"
import artLeaf5 from "../assets/artistbranch-02.png"
import artLeaf6 from "../assets/artistbranch-17.png"
import artLeaf7 from "../assets/artistbranch-07.png"
import artLeaf8 from "../assets/artistbranch-08.png"
import artLeaf9 from "../assets/artistbranch-05.png"
import artLeaf10 from "../assets/artistbranch-22.png"
import artLeaf11 from "../assets/artistbranch-21.png"
import artLeaf12 from "../assets/artistbranch-19.png"
import artLeaf13 from "../assets/artistbranch-18.png"
import artLeaf14 from "../assets/artistbranch-20.png"
import artLeaf15 from "../assets/artistbranch-14.png"
import artLeaf16 from "../assets/artistbranch-16.png"
import artLeaf17 from "../assets/artistbranch-11.png"
import artLeaf18 from "../assets/artistbranch-10.png"
import artLeaf19 from "../assets/artistbranch-15.png"
import artLeaf20 from "../assets/artistbranch-13.png"
import danaFace from "../assets/danaFace.png"
import dannyFace from "../assets/dannyFace.png"
import kimiaFace from "../assets/kimiaFace.png"
import meetTeam from "../assets/meetTeam.png"
import ourArtists from "../assets/ourArtists.png"
import mission from "../assets/missionStatementAbout.png"

function AboutUs() {
  function artistWeb() {
    // Mikayla Lacey
    window.open('https://mikaylalacey.partial.gallery/');    
  } function artistWeb2() {
    // Neil Maguire
    window.open('https://www.corkingallery.com/artists/neil-maguire/');  
  } function artistWeb3() {
    // Paula Mclean
    window.open('http://www.paulamclean.ca/');  
  } function artistWeb4() {
    // Jet Coghlan
    window.open('https://www.agreenerfuture.ca/litter-artists/jet-coghlan/');  
  } function artistWeb5() {
    // Danny Cardoso
    window.open('https://www.dannycardoso.com/');  
  } function artistWeb6() {
    // Par Nair
    window.open('https://www.artofpar.com/');  
  } function artistWeb7() {
    // Alexandru Stratulat
    window.location.href = '';  
  } function artistWeb8() {
    // Alejandro Rizzo Nervo
    window.open('https://www.rizzonervo.com/home/');  
  } 
  function artistWeb9() {
    // Devon Pryce
    window.open('https://devonpryce.partial.gallery/');  
  } function artistWeb10() {
    // Sarah Zanchetta
    window.open('https://www.sarahzanchetta.com/');  
  } function artistWeb11() {
    // Helen Tran
    window.open('https://helentran.partial.gallery/');  
  } function artistWeb12() {
    // Pegy Korat
    window.open('https://www.pegykorat.com/');  
  } function artistWeb13() {
    // Ahlena Sultana-McGarry
    window.open('https://ahlenasultana-mcgarry.partial.gallery/');  
  } function artistWeb14() {
    // Cleopatria Peterson
    window.open('https://www.cleopatria.ca/');  
  } function artistWeb15() {
    // Erica Jochim
    window.open('https://ericajochim.format.com/#1');  
  } function artistWeb16() {
    // Atkiya Raisa
    window.open('https://www.artstation.com/atkiyaraisa/profile');  
  } function artistWeb17() {
    // Marli Davis
    window.open('https://marli-davis.format.com/');  
  } function artistWeb18() {
    // Tatiana Reithofer
    window.open('https://www.instagram.com/tatjana_anandamaya/?hl=en');  
  } function artistWeb19() {
    // Grace Darakjian
    window.open('https://www.artoneacademy.com/team/grace-darakjian-2/');  
  } function artistWeb20() {
    // Nasim Salehi
    window.location.href = '';  
  }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  }; 
  
  return (
    <>
    <section id="aboutUs"> 
      <header >
        <NavBar />
      </header>

      <main className="container text-olive2 sm:mt-10" style={{padding: '5% 5% 2% 5%'}}>
        <img
          className='lg:mt-64 sm:mt-20 md:mt-80 sm:mb-[-12%] lg:scale-150 lg:ml-[35%] md:ml-[20%]'
          src={mission}
          width={350}
          height={350}
          alt='Cgac mission statement'
          onClick={artistWeb}
          />
        <br />
        <br />
        <h4 className='text-center lg:px-10 xl:px-30 sm:px-0 lg:mt-36 text-md md:text-lg lg:text-xl'>
          At Community Garden Art Collective (CGAC) we encourage expression through art and are committed to uplifting those who have made it their livelihood. We are dedicated to promoting emerging art practices and encouraging local collaborations amongst new artists. Our mission is to function as a digital platform for online exhibitions, publications, and as space to list upcoming in-person and digital art events happening in and around Toronto. CGAC will also host in-person and digital workshops as well as produce interviews and reviews of emerging art shows to encourage connections amongst new artists. CGAC’s mission is rooted in creating community, and as such aims to help young artists navigate the local art-scene, as well as making (emerging, local) art more accessible to the public. CGAC is a gathering place for artists, enthusiasts, and buyers. We hope to see you in our garden soon!
        </h4>

        <div>
        <img
            className='lg:mt-40 lg:scale-150 lg:ml-[35%] sm:mt-20 md:ml-[15%]'
            src={ourArtists}
            width={350}
            height={350}
            alt='Our Artists'
            onClick={artistWeb}
            />
        
        <h4 className='text-center text-sm md:text-lg mt-5 w-full'>click on a name to see that artists website</h4>
        </div>
       
        <div className='p-5 mt-10 text-white h-full'>
        <Slider {...settings} className=''>
          <div className='w-1/2 h-1/2'>
            <img
            className='cursor-pointer'
            src={artLeaf1}
            width={350}
            height={350}
            alt='Mikayla Lacey'
            onClick={artistWeb}
            />
            </div>
            <div className='w-1/2 h-1/2'>
            <img
            className='cursor-pointer'
            src={artLeaf2}
            width={350}
            height={350}
            alt='Neil Maguire'
            onClick={artistWeb2}
            />          
            </div>
            <div className='w-1/2 h-1/2'>
            <img
            className='cursor-pointer'
            src={artLeaf3}
            width={350}
            height={350}
            alt='Paula Mclean'
            onClick={artistWeb3}
            />          
            </div>
            <div className='w-1/2 h-1/2'>
            <img
            className='cursor-pointer'
            src={artLeaf4}
            width={350}
            height={350}
            alt='Jet Coghlan'
            onClick={artistWeb4}
            />          
            </div>
            <div className='w-1/2 h-1/2'>
            <img
            className='cursor-pointer'
            src={artLeaf5}
            width={350}
            height={350}
            alt='Danny Cardoso'
            onClick={artistWeb5}
            />          
            </div>
            <div className='w-1/2 h-1/2'>
            <img
            className='cursor-pointer'
            src={artLeaf6}
            width={350}
            height={350}
            alt='Par Nair'
            onClick={artistWeb6}
            />          
            </div>
            <div className='w-1/2 h-1/2'>
            <img
            className='cursor-pointer'
            src={artLeaf7}
            width={350}
            height={350}
            alt='Alexandru Stratulat'
            onClick={artistWeb7}
            />          
            </div>
            <div className='w-1/2 h-1/2'>
            <img
            className='cursor-pointer'
            src={artLeaf8}
            width={350}
            height={350}
            alt='Alejandro Rizzo Nervo'
            onClick={artistWeb8}
            />          
            </div>
            <div className='w-1/2 h-1/2'>
            <img
            className='cursor-pointer'
            src={artLeaf9}
            width={350}
            height={350}
            alt='yellow arrow'
            onClick={artistWeb9}
            />          
            </div>
            <div className='w-1/2 h-1/2'>
            <img
            className='cursor-pointer'
            src={artLeaf10}
            width={350}
            height={350}
            alt='yellow arrow'
            onClick={artistWeb10}
            />          
            </div>
            <div className='w-1/2 h-1/2'>
            <img
            className='cursor-pointer'
            src={artLeaf11}
            width={350}
            height={350}
            alt='yellow arrow'
            onClick={artistWeb11}
            />          
            </div>
            <div className='w-1/2 h-1/2'>
            <img
            className='cursor-pointer'
            src={artLeaf12}
            width={350}
            height={350}
            alt='yellow arrow'
            onClick={artistWeb12}
            />          
            </div>
            <div className='w-1/2 h-1/2'>
            <img
            className='cursor-pointer'
            src={artLeaf13}
            width={350}
            height={350}
            alt='yellow arrow'
            onClick={artistWeb13}
            />          
            </div>
            <div className='w-1/2 h-1/2'>
            <img
            className='cursor-pointer'
            src={artLeaf14}
            width={350}
            height={350}
            alt='yellow arrow'
            onClick={artistWeb14}
            />          
            </div>
            <div className='w-1/2 h-1/2'>
            <img
            className='cursor-pointer'
            src={artLeaf15}
            width={350}
            height={350}
            alt='yellow arrow'
            onClick={artistWeb15}
            />          
            </div>
            <div className='w-1/2 h-1/2'>
            <img
            className='cursor-pointer'
            src={artLeaf16}
            width={350}
            height={350}
            alt='yellow arrow'
            onClick={artistWeb16}
            />          
            </div>
            <div className='w-1/2 h-1/2'>
            <img
            className='cursor-pointer'
            src={artLeaf17}
            width={350}
            height={350}
            alt='yellow arrow'
            onClick={artistWeb17}
            />          
            </div>
            <div className='w-1/2 h-1/2'>
            <img
            className='cursor-pointer'
            src={artLeaf18}
            width={350}
            height={350}
            alt='yellow arrow'
            onClick={artistWeb18}
            />          
            </div>
            <div className='w-1/2 h-1/2'>
            <img
            className='cursor-pointer'
            src={artLeaf19}
            width={350}
            height={350}
            alt='yellow arrow'
            onClick={artistWeb19}
            />          
            </div>
            <div className='w-1/2 h-1/2'>
            <img
            className='cursor-pointer'
            src={artLeaf20}
            width={350}
            height={350}
            alt='yellow arrow'
            onClick={artistWeb20}
            />          
            </div>
          </Slider>
        </div>

      <img src={meetTeam} width={350} height={350} alt='Meet the Team' className='mt-10 sm:mt-20 mb-5 sm:mb-[-40%] lg:scale-150 lg:ml-[35%] lg:mb-[-5%] sm:ml-[10%]' ></img>
       
      <div className='lg:flex lg:pb-32 gap-10 sm:mt-48 justify-center items-center lg:mt-[-10%]'>
        <div className='relative md:text-sm sm:flex-col'>
          <img src={kimiaFace} alt="illustrated portrait of Kimia" height={600} width={400} className='md:mt-[-30%] lg:mt-[-75%]'/>
          <div className='sm:mb-60'>
            <p className='absolute w-full text-center ml-6 mt-5 text-l md:text-l pr-10'>Kimia Ghofrani is an artist, designer, and illustrator based in Toronto. 
            Her preferred materials are ceramics, oil, relief printing, watercolour, gouache, paper-cut, and all the craziness that is mixed media collage. Everything 
            she sees, she wants to try! Kimia is currently experimenting with 2D animation. She does what feels right for the piece and loves to experiment with new media. 
            Along her personal practice, Kimia is the co-founder of Community Garden Art Collective (CGAC). An art collective and platform for online exhibitions, selling work, 
            and sharing news and events for emerging and established artists in Toronto – spreading seeds soon to grow.
</p>
          </div>
        </div>
      
      <div className='relative md:text-sm sm:mb-96 pt-10 lg:mt-[-6%]'>
        <img src={dannyFace} alt="illustrated portrait of Danny" height={600} width={400} className='mt-10 sm:mt-60'/>
        <div className='sm:pb-96'>
          <p className='absolute w-full text-center ml-6 text-l pr-10 md:mt-2'>Danny Cardoso is a printmaking based artist with a BFA from OCAD University. Using inspiration from religious iconography, Daniel focuses on creating visual narratives using various print methods such as etching and reductive linocuts. He utilizes poetry and other creative writing techniques, along with visual anecdotes, as a means of disseminating the world through a queer lens. 
Daniel is an advocate for community building and accessibility in the arts world, and through the art collective CGAC (Community Garden Art Collective), plans and executes workshop series for underfunded and underrepresented communities in Toronto.
</p>
        </div>
      </div>
      
      <div className='relative md:text-sm bottom-48 lg:mt-[-18%] sm:mb-10'>
        <img src={danaFace} alt="illustrated portrait of Dana" height={600} width={400} className='md:mt-48 md:mb-1 lg:mt-[25%]' />
        <p className=' lg:mt-6 absolute w-full text-center ml-6 text-l pr-10'>Dana Filatov is a multidisciplinary artist based in Toronto. Her work explores themes of gender performativity, and the sociocultural symbolism(s) of food and sexuality. An MA from the University of Guelph, Dana is currently continuing her research of food in contemporary art to re-evaluate gender norms, and as such the imposition of femininity in North America, through her painting, photo, and ceramics practice. 
        Dana is also the co-founder of Community Garden Art Collective (CGAC). An art collective and platform for online exhibitions, selling work, 
            and sharing news and events for emerging and established artists in Toronto – spreading seeds soon to grow.
</p>
      </div>
      </div>
      </main>
      </section>
      
      <footer className='bg-frenchGrey sm:mt-40 lg:mt-10' >
        <Footer2 />
      </footer>
    </>
  );
} 

export default AboutUs