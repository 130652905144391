import { useRef } from "react";
import { AiFillInstagram, AiOutlineMail } from "react-icons/ai";
import { FaTiktok } from 'react-icons/fa';
import emailjs from '@emailjs/browser';
import thinWhite from "../assets/thinWhite.png";
import gotInsta from "../assets/gotInstaApricot.png";
import stayLoop from "../assets/stayLoopApricot.png";
import whiteHill from "../assets/whiteFooterHill.png";
import contactUs from "../assets/contactUsApricot.png";
import iqoi from "../assets/iqoi.png"
import inspo from "../assets/inspo.png"
import butter from "../assets/butter.png"

// functions
function Foot() {
function insta1() {
  window.location.href = "https://www.instagram.com/p/CokYPLGLhvM/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
}
function insta2() {
  window.location.href = "https://www.instagram.com/p/Cr1NQFHJvCX/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
}
function insta3() {
  window.location.href = "https://www.instagram.com/p/CZuFsL4u-Qj/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
}
const form = useRef('');
const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_ud07a0f', 'template_9dq44gi', form.current, 'JVePUTxJproHOHO2Q')
    document.getElementById("clearInput1").value = "";
    document.getElementById("clearInput2").value = "";
    document.getElementById("clearInput3").value = "";
  };

  return (
    <>
      <section id="footer" className="h-30">
        <img
        className="-mt-7 md:-mt-2 " 
        src={thinWhite}
        width={2550}
        height={200}
        alt="loop divider"
        />
        <div style={{padding: '5% 5% 2% 5%'}}>
          <div className="ml-[-1%] mb-2 mt-[-5%]">
          <img 
            src={gotInsta}
            width={300}
            height={700}
            alt="CGAC workshop poster"
            />
          </div>
           
          <div className="instaImages lg:gap-16 sm:gap-2 flex mt-4 md:mt-6 w-[100%] justify-start relative z-30">
            <div className="md:mr-10 mr-4"> 
              <img className="" onClick={insta1}
              src={iqoi}
              width={400}
              height={400}
              alt="CGAC workshop poster"
              />
            </div>
            <div className="md:mr-10 mr-4">
              <img onClick={insta2}
              src={inspo}
              width={410}
              height={410}
              alt="various bones, captioned 'what inspires you?'"
              />
            </div>
            <div>
              <img onClick={insta3}
              src={butter}
              width={410}
              height={410}
              alt="lino-cut butterfly by Minerva Mullally"
              />
            </div>
          </div>

        <div className="flex pt-12 z-10" id='bottomDiv'>
          <div id='container' className="mt-1 md:mt-10 sm:content-center z-10">
          <img 
          className="z-20 ml-[-5%] sm:ml-0" 
          src={stayLoop}
          width={400}
          height={400}
          alt="stay in the loop header"
          />

    <form ref={form} onSubmit={sendEmail} className="flex flex-col">
      <input  id='clearInput1'  type="text" placeholder="name" name="user_name" className="bg-frenchGrey placeholder-gray-500 mb-5 mt-3 rounded-xl h-10 pl-5"/>
      <input id='clearInput2'  type="email" placeholder="email address" name="user_email" className="bg-frenchGrey placeholder-gray-500 mb-5 rounded-xl h-10 pl-5"/>
      <textarea  id='clearInput3' name="message" placeholder="How can CGAC support you best? ☀️🌱" className="bg-frenchGrey placeholder-gray-500 mb-5 rounded-xl h-20 pt-2 pl-5 pr-2" />
      <button   type="submit" value="Send" id='formButton popup submitp' className="btn btn-animation font-bod mt-5 sm:mt-2 sm:mb-3">
      <p id="submitp" >submit</p>
      </button> 
    </form>
         
          <div id='contactUs' className="pt-10 z-20 ">
            <img 
            className="z-20" 
            src={contactUs}
            width={300}
            height={700}
            alt="contact us header"
            />
            <div className="flex items-center ml-4 mt-5">
              <AiFillInstagram className="scale-150 mr-2 sm:justify-start fill-persimmon" />
              <p className="text-sm md:text-lg text-persimmon">@cgac.workshop</p>
            </div>

            <div className="flex items-center ml-4 mt-1 sm:mt-3">
              <FaTiktok className="scale-150 mr-2 fill-persimmon" />
              <p className="text-sm md:text-lg text-persimmon">@cgac.toronto</p>
            </div>

            <div className="flex items-center text-end ml-4 mt-1 mb-5 sm:mt-3">
              <AiOutlineMail className="scale-150 mt-1 mr-2 fill-persimmon" />
              <p className=" text-sm md:text-lg text-persimmon">cgac.spillthebeans@gmail.com</p>
            </div>
          </div>
          </div>
            </div>
          </div>     
        <div className="flex items-center justify-center z-20">
          <p className="text-xs text-black sm:text-sm text-center font-bod mt-1 mb-5 z-10">
          &copy; 2023 Community Garden Art Collective (CGAC)
          </p>
          <div id='copyrightWithDate'></div> 
         </div>
      </section>

      <div className="relative">
        <img
        className="-mt-5 z-0 absolute bottom-0 left-[-10%]"
        src={whiteHill}
        width={5500}
        height={1000}
        alt="grass footer"
        />
      </div>
    </>
  );
}
export default Foot;