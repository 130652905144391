import NavBar from './NavBar';
import Footer3 from './Footer3';

function Calendar() {
  return (
    <>
    <section id="calendar">
      <header className="">
        <section className="">
          <NavBar />
        </section>
      </header>
      <main className="container bg-darkBlue sm:mb-[-150%] md:mb-0 lg:mb-0">
        <h2 className='lg:text-6xl sm:text-xl text-center mt-[30%] text-olive2'>🚧 calendar under construction 🚧</h2>
        <h2 className='lg:text-6xl sm:text-xl text-center mt-4 sm:mt-0 sm:mb-10 mb-0 text-olive2'>coming soon!</h2>
        <div className='lg:ml-[20%] sm:ml-[-5%] lg:mt-60 py-40 md:ml-[15%] overflow-hidden'>
        {/* <iframe className="lg:w-[900px] lg:h-[700px] sm:w-[400px] sm:h-[400px]" src="https://calendar.google.com/calendar/embed?src=cgac.spillthebeans%40gmail.com&ctz=America%2FToronto"></iframe>   <br /> */}
        <iframe src="https://widgets.commoninja.com/4c7d143b-a99b-4c24-b34b-2ce9730bfff4" className="lg:w-[900px] lg:h-[900px] md:w-[400px] md:h-[400px] sm:h-[1000px]  sm:w-[390px] overflow-hidden"></iframe>
        </div>
        <br />
       
      </main>
      <footer className='bg-persimmon'>
        <Footer3 />
      </footer>
      </section>
    </>
  );
}

export default Calendar