import './App.css';
import {
  Routes,
  Route
} from 'react-router-dom';
import Home from './Components/Home';
import MainGallery from './Components/MainGallery';
import Calendar from './Components/Calendar';
import Marketplace from './Components/Marketplace';
import AboutUs from './Components/AboutUs';

function App() {
  return (
    <>
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/MarketPlace" element={<Marketplace />} />
        <Route path="/MainGallery" element={<MainGallery />} />
        <Route path="/Calendar" element={<Calendar />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        {/* <Route path="*" element={<ErrorPage />} />         */}
      </Routes>
    </>
  )
}
export default App;
